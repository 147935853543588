import React, { useContext, Suspense, lazy } from 'react';
import { Routes as DOMRoutes, Route, useLocation } from 'react-router-dom';

import MainLayout from 'layout/MainLayout';
import AuthLayout from 'layout/AuthLayout';
import { Toaster } from 'components/ui/toaster';
import { useRefreshToken } from 'modules/auth/hooks/useRefreshToken';
import { AuthContext } from 'providers/auth';
import Loader from 'components/Loader';
import CerfiedUsers from 'pages/CertifedUsers';

const AuthPage = lazy(() => import('pages/Auth'));
const CoursesPage = lazy(() => import('pages/Courses'));
const CourseStudentsPage = lazy(() => import('pages/CourseStudents'));
const ModulesPage = lazy(() => import('pages/Modules'));
const LessonsPage = lazy(() => import('pages/Lessons'));
const QuizzesPage = lazy(() => import('pages/Quizzes'));
const NewsPage = lazy(() => import('pages/News'));
const TeachersPage = lazy(() => import('pages/Teachers'));
const VacanciesPage = lazy(() => import('pages/Vacancies'));
const CategoriesPage = lazy(() => import('pages/Categories'));
const NotificationsPage = lazy(() => import('pages/Notifications'));
const CommentsPage = lazy(() => import('pages/Comments'));
const CourseCommentPage = lazy(() => import('pages/Comments/CourseComment'));
const StoriesPage = lazy(() => import('pages/Stories'));
const ResumePage = lazy(() => import('pages/Resume'));
const SupportBotPage = lazy(() => import('pages/SupportBot'));
const ShortsPage = lazy(() => import('pages/Grandmasters'));
const SponsorsPage = lazy(() => import('pages/Sponsors'));
const Home = lazy(() => import('pages/Home'));
const Leaderboard = lazy(() => import('pages/Leaderboard'));
const Referral = lazy(() => import('pages/Referral'));
const ReferralUser = lazy(() => import('pages/ReferredUser'));

export const Routes = () => {
  const { pathname } = useLocation();
  const { isLoading } = useRefreshToken();
  const { isAuthenticated } = useContext(AuthContext);

  const routes = [
    { path: '/', element: <Home /> },
    { path: '/leaderboard', element: <Leaderboard /> },
    { path: '/courses', element: <CoursesPage /> },
    { path: '/courses/:courseId/students', element: <CourseStudentsPage /> },
    { path: '/courses/:courseId', element: <ModulesPage /> },
    { path: '/courses/:courseId/:moduleId', element: <LessonsPage /> },
    {
      path: '/courses/:courseId/:moduleId/:lessonId',
      element: <QuizzesPage />,
    },
    { path: '/news', element: <NewsPage /> },
    { path: '/vacancies', element: <VacanciesPage /> },
    { path: '/vacancies/:vacancyId', element: <ResumePage /> },
    { path: '/categories', element: <CategoriesPage /> },
    { path: '/teachers', element: <TeachersPage /> },
    { path: '/stories', element: <StoriesPage /> },
    { path: '/comments', element: <CommentsPage /> },
    { path: '/comments/:courseId', element: <CourseCommentPage /> },
    { path: '/notifications', element: <NotificationsPage /> },
    { path: '/support-bot', element: <SupportBotPage /> },
    { path: '/shorts', element: <ShortsPage /> },
    { path: '/sponsors', element: <SponsorsPage /> },
    { path: '/certified-users', element: <CerfiedUsers /> },
    { path: '/referral', element: <Referral /> },
    { path: '/referral/:referralId', element: <ReferralUser /> },
  ];

  return (
    <>
      <Suspense fallback={<Loader />}>
        {pathname === '/statistics' ? (
          <DOMRoutes>
            <Route
              path="/statistics"
              element={
                <div className="container py-10">
                  <Home isShowLogo />
                </div>
              }
            />
          </DOMRoutes>
        ) : (
          <>
            {isLoading ? null : isAuthenticated ? (
              <MainLayout>
                <DOMRoutes>
                  {routes.map(route => (
                    <Route
                      path={route.path}
                      element={route.element}
                      key={route.path}
                    />
                  ))}
                </DOMRoutes>
              </MainLayout>
            ) : (
              <AuthLayout>
                <DOMRoutes>
                  <Route path="/" element={<AuthPage />} />
                  <Route path="*" element={<AuthPage />} />
                </DOMRoutes>
              </AuthLayout>
            )}
          </>
        )}
      </Suspense>
      <Toaster />
    </>
  );
};
