import { Outlet, useLocation, useResolvedPath, Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from './ui/breadcrumb';

export default function createBreadcrumbs() {
  return [
    {
      path: '/',
      element: <Breadcrumbs text="Bosh sahifa" />,
      children: [
        {
          path: '/courses',
          element: <Breadcrumbs text="Kurslar" />,
          children: [
            {
              path: ':courseId',
              element: <Breadcrumbs text="Bo'limlar" />,
              children: [
                {
                  path: ':moduleId',
                  element: <Breadcrumbs text="Darslar" />,
                  children: [
                    {
                      path: ':lessonId',
                      element: <Breadcrumbs text="Quizlar" />,
                    },
                  ],
                },
              ],
            },
            {
              path: ':courseId/students',
              element: <Breadcrumbs text="O'quvchilar ro'yxati" />,
            },
          ],
        },
        {
          path: '/referral',
          element: <Breadcrumbs text="Referallar" />,
          children: [
            {
              path: ':referralId',
              element: <Breadcrumbs text="Referal orqali kirganlar" />,
            },
          ],
        },
        {
          path: '/news',
          element: <Breadcrumbs text="Yangiliklar" />,
        },
        {
          path: '/vacancies',
          element: <Breadcrumbs text="Vakansiyalar" />,
          children: [
            {
              path: ':resumeId',
              element: <Breadcrumbs text="Resumelar" />,
            },
          ],
        },
        {
          path: '/categories',
          element: <Breadcrumbs text="Toifalar" />,
        },
        {
          path: '/teachers',
          element: <Breadcrumbs text="O'qtuvchilar" />,
        },
        {
          path: '/stories',
          element: <Breadcrumbs text="Hikoyalar" />,
        },
        {
          path: '/comments',
          element: <Breadcrumbs text="Kurs izohlar" />,
          children: [
            {
              path: ':courseId',
              element: <Breadcrumbs text="Izohlari" />,
            },
          ],
        },
        {
          path: '/notifications',
          element: <Breadcrumbs text="E'lonlar" />,
        },
      ],
    },
  ];
}

function Breadcrumbs({ text }: { text: string }) {
  const location = useLocation();
  const resolvedLocation = useResolvedPath('');
  const isActive = location.pathname === resolvedLocation.pathname;

  return (
    <div className="flex gap-1">
      <Breadcrumb>
        <BreadcrumbList>
          {isActive ? (
            <BreadcrumbItem>
              <BreadcrumbPage>{text}</BreadcrumbPage>
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to={resolvedLocation.pathname}>{text}</Link>
              </BreadcrumbLink>
              <BreadcrumbSeparator />
            </BreadcrumbItem>
          )}
        </BreadcrumbList>
      </Breadcrumb>
      <Outlet />
    </div>
  );
}
