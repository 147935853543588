import { useQuery } from '@tanstack/react-query';
import { getPagination } from 'modules/adapter';
import { get } from 'lodash';
import { GetCertifiedList } from '../api';
import { certifiedUserList } from '../adapters';

export const useCertifiedList = (currentPage: number, courseId?: string) => {
  const initialData = {
    data: certifiedUserList(),
    paginationInfo: getPagination(),
  };

  const { data = initialData, ...args } = useQuery({
    queryKey: ['certified_users', currentPage, courseId],
    queryFn: () => GetCertifiedList(currentPage, courseId),
    select: data => ({
      data: certifiedUserList(get(data, 'data.data')),
      paginationInfo: getPagination(get(data, 'data.pagination')),
    }),
  });
  return {
    ...data,
    ...args,
  };
};
